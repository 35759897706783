import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { OpportunityTypeID } from "app/model/opportunity-type/opportunity-type";
import { ActualAmount, ActualCloseDate, ExpectedAmount, ExpectedCloseDate, OpportunityAccrualDate, OpportunityID, OpportunityItem, OpportunityName, SalesRecordingDate, WonNote } from "app/model/opportunity/opportunity";
import { WonReasonID } from "app/model/opportunity/won-reason/won-reason";
import { PhaseID } from "app/model/sales-phase/sales-phase";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type UpdateOpportunityInputsV2 = {
  id: OpportunityID,
  opportunityName: OpportunityName,
  opportunityTypeID: OpportunityTypeID,
  phaseID: PhaseID,
  assignee: UserID,
  expectedAmount?: ExpectedAmount,
  expectedCloseDate?: ExpectedCloseDate,
  opportunityAccrualDate?: OpportunityAccrualDate,
  items?: OpportunityItem[],
}

export type WinOpportunityInputsV2 = {
  opportunityID: OpportunityID,
  actualCloseDate: ActualCloseDate,
  salesRecords: {
    date: SalesRecordingDate,
    amount: ActualAmount,
  }[],
  wonNote: WonNote,
  wonReasonIDs: WonReasonID[],
}

export abstract class OpportunityCommandV2Service {
  abstract update(inputs: UpdateOpportunityInputsV2): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
  abstract win(inputs: WinOpportunityInputsV2): Observable<void>;
  abstract bulkDelete(ids: OpportunityID[]): Observable<void>
  abstract bulkRevert(ids: OpportunityID[]): Observable<void>
}
