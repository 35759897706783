import { MessageDialogService } from '#application/services/message-dialog.service';
import { OpportunityCommandV2Service, UpdateOpportunityInputsV2, WinOpportunityInputsV2 } from '#application/services/opportunity-command-v2.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { UpdateOpportunityRequest } from '#infrastructure/api/server-opportunity-api';
import { WinOpportunityRequestV2 } from '#infrastructure/api/server-opportunity-v2-api';
import { Target, messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { DateTime } from 'luxon';
import { NEVER, Observable, catchError, map, of, tap, throwError } from 'rxjs';
import {OpportunityID, OpportunityStatus} from "../../model/opportunity/opportunity";

@Injectable({
  providedIn: 'root'
})
export class OpportunityCommandV2ServiceImpl implements OpportunityCommandV2Service {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);

  update(inputs: UpdateOpportunityInputsV2): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.opportunityApi.updateOpportunity(
      inputs.id.value,
      {
        opportunityName: inputs.opportunityName.value,
        opportunityTypeID: inputs.opportunityTypeID.value,
        phaseID: inputs.phaseID.value,
        expectedAmount: inputs.expectedAmount?.value,
        assignee: inputs.assignee.value,
        expectedCloseDate: inputs.expectedCloseDate === undefined ? undefined : this._toFormatString(inputs.expectedCloseDate.value),
        opportunityAccrualDate: inputs.opportunityAccrualDate === undefined ? undefined : this._toFormatString(inputs.opportunityAccrualDate.value),
        items: inputs.items?.reduce((acc, v) => {
          acc[v.fieldId.value] = v.fieldValue.value;
          return acc;
        }, {} as { [key: string]: string }),
      } satisfies UpdateOpportunityRequest
    )
      .pipe(
        tap(() => this.snackBarService.show(messageFn(Target.OPPORTUNITY, 'UPDATE_SUCCESS').message)),
        map(() => Success(undefined)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            default:
              this.messageDialogService.notice({
                errorTarget: `${Target.OPPORTUNITY}の更新`,
              });
              return NEVER;
          }
        }),
      );
  }

  win(inputs: WinOpportunityInputsV2): Observable<void> {
    const requestBody: WinOpportunityRequestV2 = {
      actualCloseDate: this._toFormatString(inputs.actualCloseDate.value),
      salesRecords: inputs.salesRecords.map(v => ({
        date: this._toFormatString(v.date.value),
        amount: v.amount.value,
      })),
      wonNote: inputs.wonNote.value,
      wonReasonIDs: inputs.wonReasonIDs.map(v => v.value),
    };
    return this.serverApi.opportunityV2Api
      .win(inputs.opportunityID.value, requestBody)
      .pipe(
        tap(() => this.snackBarService.show(messageFn(Target.OPPORTUNITY_WON, 'CREATE_SUCCESS').message)),
        catchError(err => {
          this.snackBarService.show(messageFn(Target.OPPORTUNITY_WON, 'CREATE_ERROR').message);
          return throwError(err);
        }),
      );
  }

  private _toFormatString(arg: Date) {
    return DateTime.fromJSDate(arg).toFormat('yyyy-MM-dd');
  }

  bulkDelete(ids: OpportunityID[]): Observable<void> {
    return this.serverApi.opportunityV2Api.bulkUpdate({opportunityStatus: OpportunityStatus.DELETED, opportunityIDs: ids.map(id => id.value)})
  }

  bulkRevert(ids: OpportunityID[]): Observable<void> {
    return this.serverApi.opportunityV2Api.bulkUpdate({opportunityStatus: OpportunityStatus.OPEN, opportunityIDs: ids.map(id => id.value)})
  }
}
